import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { FC, useState } from 'react';
import { IVKI } from '../../VKI/Interfaces/IVKI';
import VKIService from '../Services/VKIService';



export type validatieProps = {
	vki: IVKI | undefined;
  bearerToken: string;
  userId: string;
};
export const Validatie: FC<validatieProps> = (props: validatieProps) => {
  const [openValidatie, setOpen] = useState(false);
  const [validatieBericht, valideer] = useState("");
  
  const valideerFormulier = () =>
  {
    if (props.vki)
    {
      VKIService.saveVki(props.vki, props.bearerToken, props.userId);
      var msg = Valideren.valideer(props.vki, true);
      valideer(msg);
      setOpen(true);
      props.vki.IsGevalideerd = (msg === "");
      
    }
  }
    return <> 
      <Dialog open={openValidatie} onClose={() => {setOpen(false)}}>
      <DialogTitle>{t('valideren')}</DialogTitle>
      <DialogContent hidden={validatieBericht !== ""} >
          <p>{t('validatieSucces')}</p>
      </DialogContent>
      <DialogContent hidden={validatieBericht === ""}>
          <p><strong>{t('validatieGeenSucces')}</strong></p>
          <p className='error' dangerouslySetInnerHTML={{ __html:validatieBericht}}></p>
      </DialogContent>
      <DialogActions>
          <Button onClick={() => {setOpen(false)}} variant="contained" color="primary" style={{ margin: "0.9375rem"}} autoFocus>
          {t('ok')}
          </Button>
      </DialogActions>
      </Dialog>
    <Button onClick={valideerFormulier} variant="contained" color="primary" style={{ margin: "0.9375rem"}} autoFocus>{t('valideer')}</Button> </>;
};

export class Valideren {
  
  static valideerVanuitFormulier:boolean = false;
  static valideerFormulier(props: validatieProps) : string
  {
    var msg = "";
    if (props.vki)
    {
      VKIService.saveVki(props.vki, props.bearerToken, props.userId);
      msg = Valideren.valideer(props.vki, true);
      props.vki.IsGevalideerd = (msg === "");
    }
    return msg;
  }

  static valideer(vki:IVKI, vanuitFormulier:boolean) : string
  {
    this.valideerVanuitFormulier = vanuitFormulier;
    var result:string = "";

    temp = this.valideerVerplichteVelden(vki);
    if(temp)
    {
      result += temp ;
    }

    var temp:String = this.valideerMortaliteitMeerDan5Procent(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    var temp:String = this.valideerKipnummer(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    var temp:String = this.valideerVKIInfoChecked(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    temp = this.valideerEmailPluimveehouder(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    temp = this.valideernaamDierenarts(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    temp = this.valideerPlaatsDierenarts(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    temp = this.valideerPlaatsSlachthuis(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    temp = this.valideerRas(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    temp = this.valideerVbnummers(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    temp = this.valideerAantalOpgezetteDieren(vki);
    if(temp)
    {
      result += temp + "<br />";
    }
    temp = this.valideerUitvalEersteWeek(vki);
    if(temp)
    {
      result += temp + "<br />";
    }
    temp = this.valideerUitvalTotaal(vki);
    if(temp)
    {
      result += temp + "<br />";
    }
    
    temp = this.valideerGecumuleerdeDagelijkseMortaliteit(vki);
    if(temp)
    {
      result += temp + "<br />";
    }
    temp = this.valideerGecumuleerdeDagelijkseMortaliteitMax100(vki);
    if(temp)
    {
      result += temp + "<br />";
    }
    temp = this.valideerTotaleDagelijkseMortaliteit(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    temp = this.valideerTotaleDagelijkseMortaliteit100(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    if (vki.Levering)
    {
      for (var i:number=0; i < vki.Levering.length;i++)
      {
        if ((vki.Levering.length-1) == i){
          temp = this.valideerDatumLaden(vki, i+1)
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerDatumLadenOpzetDatum(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }

          temp = this.valideerAanvangLaden(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerAantalLevering(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerAantalCombinaties(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          
          temp = this.valideerSlachtDatum(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerSlachtDatumDatumInvullen(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerGewicht(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerStrooiselkwaliteit(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
        }
      }
      temp = this.valideerTypeStrooisel(vki);
      if(temp)
      {
        result += temp + "<br />";
      }
    }

    if (vki.Voer)
    {
      temp = this.valideerVoer(vki);
      if(temp)
      {
        result += temp + "<br />"
      }
      for (var i:number=0; i < vki.Voer.length;i++)
      {
        temp = this.valideerVoerCocc(vki, i+1);
        if(temp)
        {
          result += temp + "<br />";
        }
        temp = this.valideerVoerBeginDatum(vki, i+1);
        if(temp)
        {
          result += temp + "<br />";
        }
        temp = this.valideerVoerBeginDatumDatumVanInvullen(vki, i+1);
        if(temp)
        {
          result += temp + "<br />";
        }
        temp = this.valideerVoerBeginDatumEindDatum(vki, i+1);
        if(temp)
        {
          result += temp + "<br />";
        }
        temp = this.valideerVoerEinddatumLaaddatum(vki, i+1);
        if(temp)
        {
          result += temp + "<br />";
        }
        temp = this.valideerBeginEindDatumWettelijkwachttermijn(vki, i+1);
        if(temp)
        {
          result += temp + "<br />";
        }
        // temp = this.valideerSlachtDatumDatumInvullen(vki, i+1)
        if(temp)
        {
          //result += temp + "<br />";
        }
        temp = this.valideerVoerEinddatumSlachtDatum(vki, i+1);
        if(temp)
        {
          result += temp + "<br />";
        }
        temp = this.valideerVoerBeginDatumVoorEindDatum(vki, i+1);
        if(temp)
        {
          result += temp + "<br />";
        }
      }
    }
    if (vki.Gezondheid)
    {
      for (var i:number=0; i < vki.Gezondheid.length;i++)
      {

        if (vki.Gezondheid[i].ZiektebeeldExterneId !== vki.ZiektebeeldNa.ExterneId)
        {

          
          temp = this.valideerGezondheidZiektebeeld(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerGezondheidZiektebeeldGeneesmiddel(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerGezondheidBeginDatum(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerGezondheidBeginDatumNaOpzet(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerGezondheidBeginDatumVoorSlacht(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerGezondheidEindDatum(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerGezondheidEindDatumWettelijkWachttermijn(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerGezondheidHoeveelheid(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
        }
      }
    }
    if (vki.Vaccins)
    {
      temp = this.valideerNCD(vki);
      if(temp)
      {
        result += temp + "<br />";
      }
      for (var i:number=0; i < vki.Vaccins.length;i++)
      {
        if (vki.Vaccins[i].Ziekte && vki.Vaccins[i].Ziekte?.indexOf("Geen") !== 0 && vki.Vaccins[i].Ziekte?.indexOf("Keine") !== 0 && vki.Vaccins[i].Methode?.trim() !== "In OVO")
        {
          temp = this.vallideerVaccinMethode(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
          temp = this.valideerVaccinDatum(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }

          temp = this.valideerVaccinEindDatumWettelijkWachttermijn(vki, i+1);
          if(temp)
          {
            result += temp + "<br />";
          }
        }
      }
    }
    
    if (vki.DuitseOndertekening && localStorage.getItem("VKILanguage") === "de")
    {
      temp = this.valideerDuitseOndertekeningRequired(vki);
      if(temp)
      {
        result += temp + "<br />";
      }
      temp = this.valideerDuitseOndertekeningAufgezogenInCheck(vki);
      if(temp)
      {
        result += temp + "<br />";
      }
    }
    temp = this.valideerSalmonellaInlegVel(vki);
    if(temp)
    {
      result += temp + "<br />";
    }
    temp = this.valideerSalmonellaOverschoen(vki);
    if(temp)
    {
      result += temp + "<br />";
    }
    temp = this.valideerOpzetdatum(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    temp = this.valideerBijlagenSalmonella(vki);
    if(temp)
    {
      result += temp + "<br />";
    }
    //Niet verplicht
    temp = this.valideerBijlagenGezondheid(vki);
    if(temp)
    {
      result += temp + "<br />";
    }
    temp = this.valideerBijlagenDierenartsverklaring(vki);
    if(temp)
    {
      result += temp + "<br />";
    }
    
    temp = this.valideerOpmerkingenCoccidiostaticumNietAanwezig(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    temp = this.valideerOpmerkingenGeneesMiddelNietAanwezig(vki);
    if(temp)
    {
      result += temp + "<br />";
    }

    temp = this.valideerOpmerkingenVaccinNietAanwezig(vki);
    if(temp)
    {
      result += temp + "<br />";
    }
    this.valideerVanuitFormulier = false;
    return result;
  }

  static valideerBijlagenSalmonella(vki: IVKI) : String
  {
    let result:string = "";
    if (vki.Bijlagen?.filter(_ => _.Soort === "Salmonella").length == 0)
    {
      result = t('valideerBijlagenSalmonella');
    }
    return result;
  }

  static valideerBijlagenGezondheid(vki: IVKI) : String
  {
    let result:string = "";
    if (vki.Bijlagen?.filter(_ => _.Soort === "Gezondheidsverklaring").length == 0)
    {
      if (vki.Mortaliteit.GecumuleerdeDagelijkseMortaliteit >= 5 || vki.Mortaliteit.TotaleDagelijkseMortaliteit > 5 )
      {
        result = t('valideerBijlagenGezondheid');
      }
    }
    return result;
  }

  static valideerBijlagenDierenartsverklaring(vki: IVKI) : String
  {
    let result:string = "";
    //if (vki.Mortaliteit?.UitvalEersteWeek > 10)
    {
      if (vki.Bijlagen?.filter(_ => _.Soort === "Dierenartsverklaring").length == 0)
      {
        result = t('valideerBijlagenDierenartsverklaring');
      }
    }
    return result;
  }

  static valideerKipnummer(vki:IVKI) :String
  {
    let result:string = "";
    if (!vki.Pluimveehouder?.Kipnummer)
    {
      result = t('kipnummerVerplicht');
    }

    return result;
  }

  static valideerEmailPluimveehouder(vki:IVKI) :String
  {
    let result:string = "";
    if (!vki.Pluimveehouder?.Naam)
    {
      result = t('emailPluimveehouder');
    }

    return result;
  }

  static valideernaamDierenarts(vki:IVKI) :String
  {
    let result:string = "";
    if (!vki.Dierenarts?.Naam)
    {
      result = t('naamDierenarts');
    }

    return result;
  }

  static valideerPlaatsDierenarts(vki:IVKI) :String
  {
    let result:string = "";
    if (!vki.Dierenarts?.Vestigingsplaats)
    {
      result = t('plaatsDierenarts');
    }

    return result;
  }

  static valideerPlaatsSlachthuis(vki:IVKI) :String
  {
    let result:string = "";
    if (!vki.Slachthuis?.Plaats)
    {
      result = t('plaatsSlachthuis');
    }

    return result;
  }
  static valideerRas(vki:IVKI) :String
  {
    let result:string = "";
    if (vki.Koppelgegevens?.Rassen?.length == 0 || (vki.Koppelgegevens?.Rassen?.length > 0 && !vki.Koppelgegevens?.Rassen[0].Naam ))
    {
      result = t('rassen');
    }

    return result;
  } 

  static valideerVbnummers(vki:IVKI) :String
  {
    let result:string = "";
    if (vki.Koppelgegevens?.VBnummers?.length == 0 || vki.Koppelgegevens?.VBnummers == null)
    {
      result = t('valideerVbnummers');
    }

    return result;
  }

  static valideerAantalOpgezetteDieren(vki:IVKI) :String
  {
    let result:string = "";
    if (vki.Mortaliteit?.AantalOpgezetteDieren <= 0)
    {
      result = t('aantalOpgezetteDieren');
    }

    return result;
  }
  static valideerUitvalEersteWeek(vki:IVKI) :String
  {
    let result:string = "";
    if (!vki.Mortaliteit?.UitvalEersteWeek)
    {
      result = t('valideerUitvalEersteWeek');
    }

    return result;
  }
  static valideerUitvalTotaal(vki:IVKI) :String
  {
    let result:string = "";
    if (!vki.Mortaliteit?.UitvalTotaal)
    {
      result = t('valideerUitvalTotaal');
    }

    return result;
  }
  static valideerGecumuleerdeDagelijkseMortaliteit(vki:IVKI) :String
  {
    let result:string = "";
    if (vki.Mortaliteit?.GecumuleerdeDagelijkseMortaliteit <= 0)
    {
      result = t('valideerGecumuleerdeDagelijkseMortaliteit');
    }

    return result;
  }
  static valideerGecumuleerdeDagelijkseMortaliteitMax100(vki:IVKI) :String
  {
    let result:string = "";
    if (vki.Mortaliteit?.GecumuleerdeDagelijkseMortaliteit > 100)
    {
      result += t('valideerGecumuleerdeDagelijkseMortaliteitMax100');
    }

    return result;
  }
  static valideerTotaleDagelijkseMortaliteit(vki:IVKI) :String
  {
    let result:string = "";
    if (vki.Mortaliteit?.TotaleDagelijkseMortaliteit <= 0)
    {
      result = t('valideerTotaleDagelijkseMortaliteit');
    }

    return result;
  }
  static valideerMortaliteitMeerDan5Procent(vki:IVKI) :String
  {
    let result:string = "";
    if (vki.Mortaliteit?.AantalOpgezetteDieren) {
      const uitvalPercentage = (vki.Mortaliteit.UitvalTotaal / vki.Mortaliteit.AantalOpgezetteDieren) * 100;
    
      if (uitvalPercentage > 5 && vki.Bijlagen?.filter(_ => _.Soort === "Dierenartsverklaring").length == 0) {
        result = t('valideerMortaliteitMeerDan5Procent');
      } else {

      }
    } else {

    }
    if (vki.Mortaliteit?.UitvalTotaal <= 0)
    {
      
    }

    return result;
  }

  static valideerTotaleDagelijkseMortaliteit100(vki:IVKI) :String
  {
    let result:string = "";
    if (vki.Mortaliteit?.TotaleDagelijkseMortaliteit > 100)
    {
      result = t('valideerTotaleDagelijkseMortaliteit100');
    }

    return result;
  }

  static valideerDatumLaden(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Levering?.length <= 0 || !vki.Levering[regel-1].DatumLaden)
    {
      if (this.valideerVanuitFormulier)
      {
        result = t('valideerDatumLaden').replace("{regel}", regel.toString());
      }
      else
      {
        result = t('valideerDatumLaden').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
      }
    }

    return result;
  }
  static valideerDatumLadenOpzetDatum(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    
    if (vki.Opzetdatum !== undefined)
    {
      var datumladen = vki.Levering[regel-1].DatumLaden;
      if (datumladen !== undefined && datumladen <= vki.Opzetdatum )
      {
        if (this.valideerVanuitFormulier)
        {
          result = t('valideerDatumLadenOpzetDatum').replace("{regel}", regel.toString());
        }
        else
        {
          result = t('valideerDatumLadenOpzetDatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
        }
      }
    }
    return result;
  }
  static valideerAanvangLaden(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Levering?.length <= 0 || !vki.Levering[regel-1].AanvangLaden )
    {
      if (this.valideerVanuitFormulier)
      {
        result = t('valideerAanvangLaden').replace("{regel}", regel.toString());
      }
      else
      {
        result = t('valideerAanvangLaden').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
      }
    }

    return result;
  }

  static valideerAantalLevering(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Levering?.length <= 0 ||  vki.Levering[regel -1].Aantal === 0 ||  vki.Levering[regel -1].Aantal === undefined )
    {
      if (this.valideerVanuitFormulier)
      {
        result = t('valideerAantalLevering').replace("{regel}", regel.toString());
      }
      else
      {
        result = t('valideerAantalLevering').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
      }
    }

    return result;
  }


  static valideerAantalCombinaties(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if(vki.Levering?.length <= 0 && vki.Levering[regel -1].DatumLaden !== undefined) {
      if (vki.Levering[regel -1].AantalCombinaties === 0 || vki.Levering[regel -1].AantalCombinaties === undefined )
      {
        if (this.valideerVanuitFormulier)
        {
          result = t('valideerAantalCombinatiesLevering').replace("{regel}", regel.toString());
        }
        else
        {
          result = t('valideerAantalCombinatiesLevering').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
        }
      }
    }

    return result;
  }
  

  static valideerSlachtDatum(vki:IVKI, regel:number, withoutLineNumber: boolean = false) :String
  {
    let result:string = "";
    if(regel -1 === 0 && !vki.Levering[regel -1].Slachtdatum && vki.Levering[regel -1].DatumLaden !== undefined && vki.Levering[regel -1].DatumLaden !== null) {
      if (this.valideerVanuitFormulier)
      {
        result = t('valideerSlachtDatum').replace("{regel}", regel.toString());
      }
      else
      {
        result = t('valideerSlachtDatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
      }
    } else if(vki.Levering[regel -1].DatumLaden !== undefined && vki.Levering[regel -1].DatumLaden !== null && !vki.Levering[regel -1].Slachtdatum) {
        if (this.valideerVanuitFormulier)
        {
          result = t('valideerSlachtDatum').replace("{regel}", regel.toString());
        }
        else
        {
          result = t('valideerSlachtDatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
        }
    }
    return result;
  }

  static valideerSlachtDatumDatumInvullen(vki:IVKI, regel:number, withoutLineNumber: boolean = false) :String
  {
    let result:string = "";
    var slachtdatum = vki.Levering[regel-1] && vki.Levering[regel-1].Slachtdatum ? vki.Levering[regel -1].Slachtdatum : undefined;
    var datumVanInvullen = vki.DatumVanInvullen ? vki.DatumVanInvullen : undefined;

    if(datumVanInvullen !== undefined && slachtdatum !== undefined && slachtdatum < datumVanInvullen) {
      if (this.valideerVanuitFormulier) {
        result = withoutLineNumber ? t('valideerSlachtDatumDatumInvullenZonderRegelNmr') : t('valideerSlachtDatumDatumInvullen').replace("{regel}", regel.toString());
      } else {
        result = withoutLineNumber ? t('valideerSlachtDatumDatumInvullenZonderRegelNmr') : t('valideerSlachtDatumDatumInvullen').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
      }
    }

    return result;
  }

  static valideerGewicht(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Levering?.length <= 0 || vki.Levering[regel-1].Gewicht === 0 || vki.Levering[regel-1].Gewicht === undefined )
    {
      if (this.valideerVanuitFormulier)
      {
        result = t('valideerGewicht').replace("{regel}", regel.toString());
      }
      else
      {
        result = t('valideerGewicht').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
      }
    }

    return result;
  }
  static valideerStrooiselkwaliteit(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Levering?.length <= 0 || !vki.Levering[regel-1].KwaliteitStrooisel )
    {
      if (this.valideerVanuitFormulier)
      {
        result = t('valideerStrooiselkwaliteit').replace("{regel}", regel.toString());
      }
      else
      {
        result = t('valideerStrooiselkwaliteit').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
      }
    }

    return result;
  }

  static valideerOpmerkingenGeneesMiddelNietAanwezig(vki:IVKI) :String
  {
    let result:string = ""; 
    if (vki.Gezondheid?.length > 0)
    {
      for (let i=0; i<vki.Gezondheid.length; i++)
      {
        if ((vki.Salmonella?.Opmerkingen === undefined || vki.Salmonella?.Opmerkingen === null || vki.Salmonella?.Opmerkingen === "") && (vki.Gezondheid[i].RegnrGebruikteGeneesmiddel?.indexOf("Niet aanwezig") >-1 || vki.Gezondheid[i].RegnrGebruikteGeneesmiddel?.indexOf("Nicht") > -1))
        {
          if (this.valideerVanuitFormulier)
          {
            result = t('valideerOpmerkingenGeneesMiddelNietAanwezig').replace("{regel}", (i+1).toString());
          }
          else
          {
            result = t('valideerOpmerkingenGeneesMiddelNietAanwezig').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
          }
        }
      }
    }

    return result;
  }

  static valideerOpmerkingenCoccidiostaticumNietAanwezig(vki:IVKI) :String
  {
    let result:string = ""; 
    if (vki.Voer?.length > 0)
    {
      for (let i=0; i<vki.Voer.length; i++)
      {
        if ((vki.Salmonella?.Opmerkingen === undefined || vki.Salmonella?.Opmerkingen === null || vki.Salmonella?.Opmerkingen === "") && (vki.Voer[i].Coccidiostaticum?.indexOf("Niet aanwezig") > -1 || vki.Voer[i].Coccidiostaticum?.indexOf("Nicht") > -1))
        {
          if (this.valideerVanuitFormulier)
          {
            result = t('valideerOpmerkingenCoccidiostaticumNietAanwezig').replace("{regel}", (i+1).toString());
          }
          else
          {
            result = t('valideerOpmerkingenCoccidiostaticumNietAanwezig').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
          }
        }
      }
    }

    return result;
  }

  static valideerOpmerkingenVaccinNietAanwezig(vki:IVKI) :String
  {
    let result:string = ""; 
    if (vki.Vaccins?.length > 0)
    {
      for (let i=0; i<vki.Vaccins.length; i++)
      {
        if ((vki.Salmonella?.Opmerkingen === undefined || vki.Salmonella?.Opmerkingen === null || vki.Salmonella?.Opmerkingen === "") && (vki.Vaccins[i].MerkTypeRegistratienummer?.indexOf("Niet aanwezig") > -1 || vki.Vaccins[i].MerkTypeRegistratienummer?.indexOf("Nicht") > -1))
        {
          if (this.valideerVanuitFormulier)
          {
            result = t('valideerOpmerkingenVaccinNietAanwezig').replace("{regel}", (i+1).toString());
          }
          else
          {
            result = t('valideerOpmerkingenVaccinNietAanwezig').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
          }
        }
      }
    }

    return result;
  }

  static valideerTypeStrooisel(vki:IVKI) :String
  {
    let result:string = "";
    if (vki.Levering?.length <= 0 || (vki.Levering?.length > 0 && !vki.Levering[0].TypeStrooisel) )
    {
      result = t('valideerTypeStrooisel');
    }

    return result;
  }

  static valideerVoer(vki:IVKI) :String
  {
    let result:string = "";
    if (vki.Voer?.length <= 0 || (vki.Voer?.length > 0 && (vki.Voer[0].SoortVoer === undefined ||vki.Voer[0].SoortVoer === null || vki.Voer[0].SoortVoer === "" ) ))
    {
      result = t('valideerVoer')
    }

    return result;
  }

  static valideerVoerCocc(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Voer?.length <= 0 || (vki.Voer?.length >= regel && !vki.Voer[regel-1].Coccidiostaticum && vki.Voer[regel-1].SoortVoer))
    {
      if (this.valideerVanuitFormulier)
      {
        result = t('valideerVoerCocc').replace("{regel}", regel.toString());
      }
      else
      {
        result = t('valideerVoerCocc').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
      }
      
    }

    return result;
  }

  static valideerVoerBeginDatum(vki:IVKI, regel:number) :String
  {
    let result:string = "";

    if (vki.Voer[regel-1].SoortVoer !== undefined)
    {
      if ((vki.Voer[regel-1].SoortVoer != null || vki.Voer[regel-1].SoortVoer !== undefined || vki.Voer[regel-1].SoortVoer !== "") && vki.Voer?.length <= 0 || (vki.Voer?.length >= regel && !vki.Voer[regel-1].Begindatum) )
      {
        if (this.valideerVanuitFormulier)
        {
          result = t('valideerVoerBeginDatum').replace("{regel}", regel.toString());
        }
        else
        {
          result = t('valideerVoerBeginDatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
        }
      }
   }
    return result;
  }

  static valideerVoerBeginDatumDatumVanInvullen(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Voer.length >= regel)
    {
      var begindatum = vki.Voer[regel-1].Begindatum;
      
      if ((vki.Voer[regel-1].SoortVoer != null || vki.Voer[regel-1].SoortVoer !== undefined || vki.Voer[regel-1].SoortVoer !== "") && vki.Voer[regel-1].SoortVoer?.indexOf("nd") == -1 && vki.Voer?.length <= 0 ||  begindatum !== undefined && begindatum >= vki.DatumVanInvullen )
      {
        if (vki.Voer[regel-1].SoortVoer?.indexOf("nd") == -1)
          {
            if (this.valideerVanuitFormulier)
            {
              result = t('valideerVoerBeginDatumDatumVanInvullen').replace("{regel}", regel.toString());
            }
            else
            {
              result = t('valideerVoerBeginDatumDatumVanInvullen').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
            }
        }
      }
    }
    return result;
  }
 static valideerVoerBeginDatumEindDatum(vki: IVKI, regel: number): string {
    let result: string = "";

    if (vki.Voer && vki.Voer.length >= regel + 1) {
      const voerItem = vki.Voer[regel - 1];
      const begindatum: Date | undefined = voerItem?.Begindatum;
      let eindDatum: Date | undefined = undefined;
      var tempEinddatum = voerItem.Einddatum;
      if (tempEinddatum !== undefined && tempEinddatum !== null ) {
        eindDatum = new Date(tempEinddatum);
      } else if (voerItem?.Einddatum !== undefined) {
        eindDatum = new Date(voerItem.Einddatum);
      }

      if (eindDatum !== undefined) {
        eindDatum.setDate(eindDatum.getDate() + 2);

        if (vki.Voer?.length >= 1 && begindatum !== undefined && begindatum >= vki.DatumVanInvullen) {
          if (this.valideerVanuitFormulier)
          {
            result = t('valideerVoerBeginDatumVoorEindDatum').replace("{regel}", regel.toString());
          }
          else
          {
            result = t('valideerVoerBeginDatumVoorEindDatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
          }
        }
      }
    }

    return result;
  }

  static valideerBeginEindDatumWettelijkwachttermijn(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Voer.length >= regel)
    {
      var begindatum = vki.Voer[regel-1].Begindatum;
      var eindDatum = vki.Voer[regel-1].Einddatum;
      var wettelijkWachttermijn = vki.Voer[regel-1].WettelijkeWachttermijn;
      const laatsteSlachtDatum = this.getLaatsteSlachtDatum(vki);

      if(begindatum !== undefined && eindDatum !== undefined && wettelijkWachttermijn !== undefined) {
        var beginDatumWettelijkWachttermijn = new Date(begindatum);
        beginDatumWettelijkWachttermijn.setDate(beginDatumWettelijkWachttermijn.getDate() + Number(wettelijkWachttermijn));
        if(eindDatum < beginDatumWettelijkWachttermijn) {
          if (this.valideerVanuitFormulier)
          {
            result = t('valideerVoerEindDatumWettelijkWachttermijn').replace("{regel}", regel.toString());
          }
          else
          {
            result = t('valideerVoerEindDatumWettelijkWachttermijnZonderRegelNmr').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
          }
        } else if(laatsteSlachtDatum !== undefined && beginDatumWettelijkWachttermijn > laatsteSlachtDatum) {
          if (this.valideerVanuitFormulier)
          {
            result = t('valideerVoerEinddatumSlachtDatum').replace("{regel}", regel.toString());
          }
          else
          {
            result = t('valideerVoerEinddatumSlachtDatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "");
          }
        }
      }
    }
    return result;
  }

  static valideerVoerEinddatumSlachtDatum(vki: IVKI, regel: number): string {
    let result: string = "";
    const vkiCopy: IVKI = { ...vki };
  
    if (vkiCopy.Voer.length >= regel) {
      const begindatum = vkiCopy.Voer[regel - 1].Begindatum;
      const eindDatum = vkiCopy.Voer[regel - 1].Einddatum;
      const laatsteSlachtDatum = this.getLaatsteSlachtDatum(vkiCopy);
  
      if (eindDatum !== undefined && laatsteSlachtDatum !== undefined) {
        // Get the year, month, and day of the original end date
        const eindDatumYear = eindDatum.getFullYear();
        const eindDatumMonth = eindDatum.getMonth();
        const eindDatumDay = eindDatum.getDate();
  
        // Specify the number of days to add here
        const numberOfDaysToAdd = Number(vki.Voer[regel-1].WettelijkeWachttermijn);
  
        // Calculate the modified end date
        const modifiedEindDatum = new Date(eindDatumYear, eindDatumMonth, eindDatumDay);


        
        modifiedEindDatum.setDate(modifiedEindDatum.getDate() + numberOfDaysToAdd);
        // Check if the modified end date is greater than the last slaughter date
        if (modifiedEindDatum > laatsteSlachtDatum) {
          if (this.valideerVanuitFormulier)
          {
            result = t('valideerVoerEinddatumSlachtDatum').replace("{regel}", regel.toString());
          }
          else
          {
            result = t('valideerVoerEinddatumSlachtDatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
          }
        }
      }
    }
    return result;
  }
  

  static valideerVoerBeginDatumVoorEindDatum(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Voer.length >= regel)
    {
      var begindatum = vki.Voer[regel-1].Begindatum;
      var einddatum = vki.Voer[regel-1].Einddatum;
      if (einddatum !== undefined && begindatum != undefined && begindatum > einddatum)
      {
        if (this.valideerVanuitFormulier)
        {
          result = t('valideerVoerBeginDatumVoorEindDatum').replace("{regel}", regel.toString());
        }
        else
        {
          result = t('valideerVoerBeginDatumVoorEindDatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
        }
      }
    }
    return result;
  }

  static valideerEindDatumNaBeginDatum(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Voer.length >= regel)
    {
      var begindatum = vki.Voer[regel-1].Begindatum;
      var einddatum = vki.Voer[regel-1].Einddatum;
      if (einddatum !== undefined && begindatum != undefined && einddatum < begindatum)
      {
        if (this.valideerVanuitFormulier)
        {
          result = t('valideerVoerEindDatumNaBeginDatum').replace("{regel}", regel.toString());
        }
        else
        {
          result = t('valideerVoerEindDatumNaBeginDatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
        }
      }
    }
    return result;
  }

  static valideerVoerEinddatumLaaddatum(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Voer.length >= regel)
    {
      var einddatum = vki.Voer[regel-1].Einddatum;
      var laatsteLaaddatum = this.getLaatsteLaaddatum(vki);
      if (einddatum !== undefined && laatsteLaaddatum != undefined)
      {
        var einddatumDate = new Date(einddatum);
        var laatsteLaaddatummin1 = new Date(laatsteLaaddatum.getDate() -1)
        var laatsteLaaddatumplus1 = new Date(laatsteLaaddatum.getDate() +1)
        var soortvoer = vki.Voer[regel-1].SoortVoer;
        if (soortvoer !== undefined && soortvoer !== null && soortvoer.indexOf('nd') > -1 && einddatum > laatsteLaaddatummin1 && einddatum < laatsteLaaddatumplus1 )
        {
          if (this.valideerVanuitFormulier)
          {
            result = t('valideerVoerEinddatumLaaddatum').replace("{regel}", regel.toString());
          }
          else
          {
            result = t('valideerVoerEinddatumLaaddatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
          }
        }
      }
    }
    return result;
  }
  

  static valideerGezondheidZiektebeeld(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Gezondheid?.length === 0 || (vki.Gezondheid?.length >= 1 && (vki.Gezondheid[0].Ziektebeeld?.indexOf("Ni") === 0 || !vki.Gezondheid[0].Ziektebeeld ) ))
    {
      if (this.valideerVanuitFormulier)
      {
        result = t('valideerGezondheidZiektebeeld').replace("{regel}", regel.toString());
      }
      else
      {
        result = t('valideerGezondheidZiektebeeld').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
      }
    }

    return result;
  }


  static valideerGezondheidZiektebeeldGeneesmiddel(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Gezondheid?.length === 0 || (vki.Gezondheid?.length >= 1 && (vki.Gezondheid[0].RegnrGebruikteGeneesmiddel?.indexOf("Ni") === 0 || !vki.Gezondheid[0].RegnrGebruikteGeneesmiddel ) ))
    {
      if (this.valideerVanuitFormulier)
      {
        result = t('valideerGezondheidZiektebeeldGeneesmiddel').replace("{regel}", regel.toString());
      }
      else
      {
        result = t('valideerGezondheidZiektebeeldGeneesmiddel').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
      }
    }

    return result;
  }


  static valideerGezondheidBeginDatum(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Gezondheid.length >= regel && vki.Gezondheid[regel-1].Ziektebeeld?.indexOf("Geen") !== 0 || vki.Gezondheid[regel-1].Ziektebeeld?.indexOf("Keine") !== 0)
    {
      var begindatum = vki.Gezondheid[regel-1].Begindatum;
      if (vki.Gezondheid[regel-1].Ziektebeeld && begindatum === undefined)
      {

        if (this.valideerVanuitFormulier)
        {
          result = t('valideerGezondheidBeginDatum').replace("{regel}", regel.toString());
        }
        else
        {
          result = t('valideerGezondheidBeginDatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
        }
      }
    }
    return result;
  }

  static getLaatsteSlachtDatum(vki:IVKI) : Date | undefined
  {
    var laatsteSlachtDatum = new Date(1964,1,1,1,1,1);
    for (var i=0 ;i<vki.Levering.length; i++)
    {
      var slachtdatum = vki.Levering[i].Slachtdatum;
      if (slachtdatum !== undefined &&  slachtdatum > laatsteSlachtDatum)
        laatsteSlachtDatum = slachtdatum;
    }
    return laatsteSlachtDatum
  }
  static getLaatsteLaaddatum(vki:IVKI) : Date | undefined
  {
    var laatsteLaaddatum = new Date(1964,1,1,1,1,1);
    for (var i=0 ;i<vki.Levering.length; i++)
    {
      var laaddatum = vki.Levering[i].DatumLaden;
      if (laaddatum !== undefined &&  laaddatum > laatsteLaaddatum)
        laatsteLaaddatum = laaddatum;
    }
    return laatsteLaaddatum
  }
  static valideerGezondheidBeginDatumVoorSlacht(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Gezondheid.length >= regel)
    {
      var begindatum = vki.Gezondheid[regel-1].Begindatum;
      var laatsteSlachtDatum = this.getLaatsteSlachtDatum(vki);
      if (begindatum !== undefined && laatsteSlachtDatum !== undefined && begindatum > laatsteSlachtDatum )
      {
        if (this.valideerVanuitFormulier)
        {
          result = t('valideerGezondheidBeginDatumVoorSlacht').replace("{regel}", regel.toString());
        }
        else
        {
          result = t('valideerGezondheidBeginDatumVoorSlacht').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
        }
      }
    }
    return result;
  }

  static valideerGezondheidBeginDatumNaOpzet(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Gezondheid.length >= regel)
    {
      var begindatum = vki.Gezondheid[regel-1].Begindatum;
      var opzetdatum = vki.Opzetdatum;
      if (begindatum !== undefined && opzetdatum !== undefined && begindatum < opzetdatum )
      {
        if (this.valideerVanuitFormulier)
        {
          result = t('valideerGezondheidBeginDatumNaOpzet').replace("{regel}", regel.toString());
        }
        else
        {
          result = t('valideerGezondheidBeginDatumNaOpzet').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
        }
      }
    }
    return result;
  }

  static valideerGezondheidEindDatum(vki: IVKI, regel: number, withoutLineNumber: boolean = false): string {
    let result: string = "";
  
    if (vki.Gezondheid.length >= regel) {
      const healthEntry = vki.Gezondheid[regel - 1];
      const { Begindatum, Einddatum, Ziektebeeld, ZiektebeeldExterneId } = healthEntry;
  
      // id 1 is geen/ kein ziektebeeld.
      if (Ziektebeeld && ZiektebeeldExterneId !== '1') {
        if (Einddatum !== undefined && Begindatum !== undefined) {
          if (Einddatum < Begindatum) {
            if (this.valideerVanuitFormulier) {
              result = withoutLineNumber ? t('valideerGezondheidEindDatumZonderRegelNmr') : t('valideerGezondheidEindDatum').replace("{regel}", regel.toString());
            } else {
              result = withoutLineNumber ? t('valideerGezondheidEindDatumZonderRegelNmr') : t('valideerGezondheidEindDatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
            }
          }
        } else {
          if (Einddatum === undefined) {
            result = t('valideerGezondheidEinddatumVerplicht');
          }
        }
      }
    }
  
    return result;
  }

  static valideerGezondheidEindDatumWettelijkWachttermijn(vki: IVKI, regel: number, zonderRegelNummer:boolean = false): string {
    let result: string = "";
    
    if (vki.Gezondheid.length >= regel) {
      var einddatum = vki.Gezondheid[regel - 1].Einddatum;
      var wettelijkeWachttermijn = vki.Gezondheid[regel - 1].WettelijkeWachttermijn ? vki.Gezondheid[regel - 1].WettelijkeWachttermijn : 0;
      var laatsteSlachtDatum = this.getLaatsteSlachtDatum(vki);
  
      if (einddatum !== undefined && laatsteSlachtDatum !== undefined) {
        var verplichteEinddatum = new Date(einddatum);
        verplichteEinddatum.setDate(einddatum.getDate() + (wettelijkeWachttermijn + 1));
        if (verplichteEinddatum > laatsteSlachtDatum) {
          if (this.valideerVanuitFormulier)
          {
            if(zonderRegelNummer) {
              result = t('valideerGezondheidEindDatumWettelijkWachttermijnZonderRegelNmr');
            } else {
              result = t('valideerGezondheidEindDatumWettelijkWachttermijn').replace("{regel}", regel.toString());
            }
          }
          else
          {
            if(zonderRegelNummer) {
              result = t('valideerGezondheidEindDatumWettelijkWachttermijnZonderRegelNmr');
            } else {
              result = t('valideerGezondheidEindDatumWettelijkWachttermijn').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
            }
          }
        }
      }
    }
    
    return result;
  }

  static valideerVaccinEindDatumWettelijkWachttermijn(vki: IVKI, regel: number, zonderRegelNummer:boolean = false): string {
    let result: string = "";
    
    if (vki.Vaccins.length >= regel) {
      var einddatum = vki.Vaccins[regel - 1].Datum;
      var wettelijkeWachttermijn = vki.Vaccins[regel - 1].WettelijkeWachttermijn ? vki.Vaccins[regel - 1].WettelijkeWachttermijn : 0;
      var laatsteSlachtDatum = this.getLaatsteSlachtDatum(vki);
  
      if (einddatum !== undefined && laatsteSlachtDatum !== undefined) {
        var verplichteEinddatum = new Date(einddatum);
        verplichteEinddatum.setDate(verplichteEinddatum.getDate() + (wettelijkeWachttermijn + 1));
        if (verplichteEinddatum > laatsteSlachtDatum) {
          if (this.valideerVanuitFormulier)
          {
            if(zonderRegelNummer) {
              result = t('valideerGezondheidEindDatumWettelijkWachttermijnZonderRegelNmr');
            } else {
              result = t('valideerVaccinEindDatumWettelijkWachttermijn').replace("{regel}", regel.toString());
            }
          }
          else
          {
            if(zonderRegelNummer) {
              result = t('valideerGezondheidEindDatumWettelijkWachttermijnZonderRegelNmr');
            } else {
              result = t('valideerVaccinEindDatumWettelijkWachttermijn').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
            }
          }
        }
      }
    }
    
    return result;
  }
  
  static valideerGezondheidHoeveelheid(vki:IVKI, regel:number, zonderRegelNummer:boolean = false) :String
  {
    let result:string = "";
    if (vki.Gezondheid.length >= regel && vki.Gezondheid[regel-1].Ziektebeeld !== null && vki.Gezondheid[regel-1].Ziektebeeld !== undefined && vki.Gezondheid[regel-1].Ziektebeeld !== "" && vki.Gezondheid[regel-1].ZiektebeeldExterneId !== "1" )
    {
      if(vki.Gezondheid[regel-1].TotaalGebruikteHoeveelheid) {

        if ( (vki.Gezondheid[regel-1].TotaalGebruikteHoeveelheid <= 0) )
        {
          if (this.valideerVanuitFormulier)
          {
            if(zonderRegelNummer) {
              result = t('valideerGezondheidHoeveelheidZonderRegelNmr');
            } else {
              result = t('valideerGezondheidHoeveelheid').replace("{regel}", regel.toString());
            }
          }
          else
          {
            if(zonderRegelNummer) {
              result = t('valideerGezondheidHoeveelheidZonderRegelNmr');
            } else {
              result = t('valideerGezondheidHoeveelheid').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
            }
          }
        }
      } else {
        result = t('valideerGezondheidHoeveelheidVerplicht');
      }
    }
    return result;
  }

  static vallideerVaccinMethode(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Vaccins.length >= regel)
    {
      if ( (vki.Vaccins[regel-1].Ziekte && vki.Vaccins[regel-1].Ziekte.indexOf('Ni') != 0 && vki.Vaccins[regel-1].Ziekte.indexOf('Geen') != 0 && !vki.Vaccins[regel-1].Methode) )
      {
        if (this.valideerVanuitFormulier)
        {
          result = t('vallideerVaccinMethode').replace("{regel}", regel.toString());
        }
        else
        {
          result = t('vallideerVaccinMethode').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
        }
      }
    }
    return result;
  }

  static valideerVaccinDatum(vki:IVKI, regel:number) :String
  {
    let result:string = "";
    if (vki.Vaccins.length >= regel)
    {
      var mdate = vki.Vaccins[regel-1].Datum;
      var datum = mdate;
      if (mdate)
      {
        datum = new Date(mdate);
      }
      var Opzetdatum = vki.Opzetdatum;
      var laatsteSlachtDatum = this.getLaatsteSlachtDatum(vki);
      if ( (laatsteSlachtDatum !== undefined && datum !== undefined &&  Opzetdatum !== undefined && Opzetdatum >  datum  && datum < laatsteSlachtDatum ) )
      {
        if (this.valideerVanuitFormulier)
        {
          result = t('valideerVaccinDatum').replace("{regel}", regel.toString());
        }
        else
        {
          result = t('valideerVaccinDatum').replace("in Zeile {regel}", "").replace("in Zeile {regel}", "bij regel {regel}");
        }
      }
    }
    return result;
  }

  static valideerNietAanwezig(field:string) : boolean
  {
    let result = false;
    var value = field?.toLocaleLowerCase()
    if (value !== null && value !== undefined )
    {
      result = value.indexOf('niet') > -1 || value.indexOf('nich') > -1 || value.indexOf('geen') > -1 || value.indexOf('keine') > -1;
    }
    return result;
  }
  static valideerNCD(vki:IVKI) :String
  {
    let result:string = "";
    let hasNCD:boolean = false;

    for (var i:number=0; i < vki.Vaccins.length;i++)
    {
      hasNCD = hasNCD || vki.Vaccins[i]?.Ziekte?.indexOf('NCD') > -1;
      console.log("hasNCD" + hasNCD);
      console.log(vki.Vaccins[i]?.Ziekte);

    }

    if (!hasNCD && !(vki.Salmonella.Opmerkingen !== null && vki.Salmonella.Opmerkingen !== undefined && vki.Salmonella.Opmerkingen !== "" ))
    {
      result = t('valideerNCD');
    }
    return result;
  }

  static valideerSalmonellaInlegVel(vki:IVKI) :String
  {
    let result:string = "";
    if (!vki.Salmonella?.SalmonellaStatusInlegvel)
    {
      result = t('valideerSalmonellaInlegVel');
    }
    return result;
  }

  static valideerSalmonellaOverschoen(vki:IVKI) :String
  {
    let result:string = "";
    if (!vki.Salmonella?.SalmonellaStatusOverschoen)
    {
      result = t('valideerSalmonellaOverschoen');
    }
    return result;
  }
  static valideerOpzetdatum(vki: IVKI): string {
    let result: string = "";
    if (vki.Opzetdatum)
    {
      var opzetdatum = new Date(vki.Opzetdatum);
      opzetdatum.setDate(opzetdatum.getDate() + 21);
      
      if (opzetdatum && opzetdatum > new Date()) {
        result = t('valideerOpzetdatum');
      }
    }
    else{
      result = t('valideerOpzetdatum');
    }
    return result;
  }

  static valideerDuitseOndertekeningRequired(vki: IVKI): string {
    let result: string = "";
    if (vki.DuitseOndertekening)
    {
      // get one of the combobox has to be true, if all false error.
      if(!vki.DuitseOndertekening.GeborenIn && !vki.DuitseOndertekening.GeborenAufgezogenIn && !vki.DuitseOndertekening.Aufgezogen) {
        result = t('DuitseOndertekeningIsVerplicht');
      }
    }
    else{
      result = t('DuitseOndertekeningIsVerplicht');
    }
    return result;
  }

  // string is required when aufgezogen in is checked true.
  static valideerDuitseOndertekeningAufgezogenInCheck(vki: IVKI): string {
    let result: string = "";
    // get one of the combobox has to be true, if all false error.
    if(vki.DuitseOndertekening && vki.DuitseOndertekening.Aufgezogen) {
      if(vki.DuitseOndertekening.AufgezogenIn === "" || vki.DuitseOndertekening.AufgezogenIn === undefined || vki.DuitseOndertekening.AufgezogenIn === null) {
        result = t('DuitseOndertekeningAugfgezogenInVerplicht');
      } 
    }

    return result;
  }

  //bij opzetdatum
  static actieOpzetDatum (vki:IVKI) :IVKI
  {
    if (vki.Voer?.length > 0 && vki.Voer[0]?.SoortVoer != undefined && vki.Voer[0]?.SoortVoer?.indexOf("Start") > -1) 
    {
      vki.Voer[0].Begindatum = vki.Opzetdatum;
    }
    return vki;
  }

  static valideerVKIInfoChecked(vki:IVKI) :String
  {
    let result:string = "";
    if (!vki.HasCheckedVkiInfo) 
    {
      result = t('vkiInfoCheckedValidations');
    }

    return result;
  }
  //bij dierenarts vestigingsplaats en bij aantal opgezette dieren
  static actieAantalopgezetIsAantal1eLevering (vki:IVKI) :IVKI
  {
    if (vki.Dierenarts?.Vestigingsplaats && vki.Dierenarts?.Vestigingsplaats?.toLowerCase()?.indexOf("hubbard") > -1 ||
    vki.Koppelgegevens.Rassen.length > 0 && vki.Koppelgegevens.Rassen[0]?.Naam.toLowerCase()?.indexOf("hubbard") > -1 ||
    vki.Koppelgegevens.Rassen.length > 1 && vki.Koppelgegevens.Rassen[1]?.Naam.toLowerCase()?.indexOf("hubbard") > -1 ||
    vki.Koppelgegevens.Rassen.length > 2 && vki.Koppelgegevens.Rassen[2]?.Naam.toLowerCase()?.indexOf("hubbard") > -1 ||
    vki.Dierenarts?.Vestigingsplaats && vki.Dierenarts?.Vestigingsplaats?.toLowerCase()?.indexOf("ja757") > -1 ||
    vki.Koppelgegevens.Rassen.length > 0 && vki.Koppelgegevens.Rassen[0]?.Naam.toLowerCase()?.indexOf("ja757") > -1 ||
    vki.Koppelgegevens.Rassen.length > 1 && vki.Koppelgegevens.Rassen[1]?.Naam.toLowerCase()?.indexOf("ja757") > -1 ||
    vki.Koppelgegevens.Rassen.length > 2 && vki.Koppelgegevens.Rassen[2]?.Naam.toLowerCase()?.indexOf("ja757") > -1 ||
    vki.Dierenarts?.Vestigingsplaats && vki.Dierenarts?.Vestigingsplaats?.toLowerCase()?.indexOf("ja987") > -1 ||
    vki.Koppelgegevens.Rassen.length > 0 && vki.Koppelgegevens.Rassen[0]?.Naam.toLowerCase()?.indexOf("ja987") > -1 ||
    vki.Koppelgegevens.Rassen.length > 1 && vki.Koppelgegevens.Rassen[1]?.Naam.toLowerCase()?.indexOf("ja987") > -1 ||
    vki.Koppelgegevens.Rassen.length > 2 && vki.Koppelgegevens.Rassen[2]?.Naam.toLowerCase()?.indexOf("ja987") > -1 ) 
    {
      if (vki.Mortaliteit?.AantalOpgezetteDieren)
      {
        if (vki.Levering == null || vki.Levering == undefined || vki.Levering?.length == 0)
        {
          vki.Levering = []
          vki.Levering.push({ Aantal : vki.Mortaliteit.AantalOpgezetteDieren, Laden: "", DatumLaden: undefined, AanvangLaden : "", Slachtdatum: undefined, AantalCombinaties : 0, Gewicht: 0, TypeStrooisel: undefined, KwaliteitStrooisel: undefined, TypeStrooiselExterneId: "", KwaliteitStrooiselExterneId: "" });
        }
        else
        {
          vki.Levering[0].Aantal = vki.Mortaliteit.AantalOpgezetteDieren;
        }
      }
    }
    return vki;
  }

  //Bij datumladen n en bij aanvang laden n
  static zetSlachtDatum (vki:IVKI, regel:number) :IVKI
  {
    let datumladen:Date | undefined = vki.Levering[(regel-1)].DatumLaden;
    if (vki.Levering?.length > (regel-1) && datumladen !== undefined)
    {
      let datum:Date = new Date(datumladen);
      let aanvangladen:string | undefined  = vki.Levering[regel-1].AanvangLaden;
      if (aanvangladen !== null && aanvangladen !== undefined)
      {
        if (typeof aanvangladen === "string" && parseInt(aanvangladen.split(':')[0]) > 18)
        {
          datum.setDate(datum.getDate() + 1);
        }
      }
      vki.Levering[(regel-1)].Slachtdatum = datum;
    }
    return vki;
  }

  //  bij datum laden n
  static zetLaatsteLaadDatum (vki:IVKI) :IVKI
  {
    if (vki.Levering?.length > 0)
    {
      vki.LaatsteLaadDatum = vki.Levering[vki.Levering.length -1].DatumLaden;
    }
    return vki;
  }

    //  bij verandering slachtdatum
    static zetLaatsteSlachtDatum (vki:IVKI) :IVKI
    {
      if(vki) {
        if (vki.Levering?.length > 0)
        {
          // set de laatste slachtdatum , dit moet de hoogste datum zijn...
          let laatsteSlachtDatum: Date | undefined = vki.LaatsteLaadDatum? vki.LaatsteLaadDatum : undefined;

          for (const levering of vki.Levering) {
            if (levering.Slachtdatum) {
              if (laatsteSlachtDatum === undefined || levering.Slachtdatum > laatsteSlachtDatum) {
                laatsteSlachtDatum = levering.Slachtdatum;
              }
            }
          }
          
          vki.LaatsteSlachtDatum = laatsteSlachtDatum;
        }
      }
      return vki;
    }

    // bij Soortvoer n
    static zetBeginDatumVoer(vki:IVKI, regel:number) :IVKI
    {
      if (vki.Voer?.length > (regel-1))
      {
        let soortvoer:string | undefined = vki.Voer[(regel-1)].SoortVoer;
        if (soortvoer != undefined && (soortvoer.indexOf('Start Voer') > -1 || soortvoer.indexOf('Starter') > -1) && vki.Opzetdatum !== undefined)
        {
          vki.Voer[(regel-1)].Begindatum = vki.Opzetdatum
        }
      }
      return vki;
    }

    // bij Soortvoer n
    static zetEindDatumVoer(vki:IVKI, regel:number) :IVKI
    {
      if (vki.Voer?.length > (regel-1) && vki.Levering?.length > (regel-1))
      {
        let soortvoer:string | undefined = vki.Voer[(regel-1)].SoortVoer;
        let coc:string | undefined = vki.Voer[(regel-1)].Coccidiostaticum;
        let laaddatum:Date | undefined = vki.Levering[(regel - 1)].DatumLaden;
        if (soortvoer != undefined && coc !== undefined && (soortvoer.indexOf('nd') > -1 && (coc.indexOf('Geen') == -1 || coc.indexOf('Keine') == -1) ) && laaddatum !== undefined)
        {
          vki.Voer[(regel-1)].Einddatum = laaddatum;
        }
      }
      return vki;
    }

    // bij Soortvoer n en Coccidiostaticum n
    static zetEindDatumVoerLeeg(vki:IVKI, regel:number) :IVKI
    {
      if (vki.Voer?.length > (regel-1) && vki.Levering?.length > (regel-1))
      {
        let soortvoer:string | undefined = vki.Voer[(regel-1)].SoortVoer;
        let coc:string | undefined = vki.Voer[(regel-1)].Coccidiostaticum;
        if (soortvoer != undefined && coc !== undefined && (soortvoer.indexOf('nd') > -1 && (coc.indexOf('Geen') > -1 || coc.indexOf('Keine') > -1) ))
        {
          vki.Voer[(regel-1)].Einddatum = undefined;
        }
      }
      return vki;
    }

    // bij Soortvoer n
    static zetCocLeeg(vki:IVKI, regel:number) :IVKI
    {
      if (vki.Voer.length > (regel-1) && vki.Levering?.length > (regel-1))
      {
        let soortvoer:string | undefined = vki.Voer[(regel-1)].SoortVoer;
        let coc:string | undefined = vki.Voer[(regel-1)].Coccidiostaticum;
        if (soortvoer != undefined && coc !== undefined && soortvoer.indexOf('nd') > -1 || (soortvoer == undefined ))
        {
          if ( vki.Voer?.length > regel)
          {
            vki.Voer[(regel)].Coccidiostaticum = "";
          }
        }
      }
      return vki;
    }

    // bij Soortvoer n
    static zetSoortvoer(vki:IVKI, regel:number) :IVKI
    {
      if (vki.Voer?.length > (regel-1) && vki.Levering?.length > (regel-1))
      {
        let soortvoer:string | undefined = vki.Voer[(regel-1)].SoortVoer;
        let coc:string | undefined = vki.Voer[(regel-1)].Coccidiostaticum;
        if (soortvoer != undefined && coc !== undefined && soortvoer.indexOf('nd') > -1 || (soortvoer == undefined ))
        {
          if ( vki.Voer.length > regel)
          {
            vki.Voer[(regel)].SoortVoer = undefined;
          }
        }
      }
      return vki;
    }

    // bij Soortvoer n
    static zetEindDatumVoerVolgendeRegelLeeg(vki:IVKI, regel:number) :IVKI
    {
      if (vki.Voer.length > (regel-1) && vki.Levering?.length > (regel-1))
      {
        let soortvoer:string | undefined = vki.Voer[(regel-1)].SoortVoer;
        let coc:string | undefined = vki.Voer[(regel-1)].Coccidiostaticum;
        if (soortvoer != undefined && coc !== undefined && soortvoer.indexOf('nd') > -1 || (soortvoer == undefined ))
        {
          if ( vki.Voer?.length > regel)
          {
            vki.Voer[(regel)].Einddatum = undefined;
          }
        }
      }
      return vki;
    }

    // bij Soortvoer n
    static zetBegindatumVoerVolgendeRegelLeeg(vki:IVKI, regel:number) :IVKI
    {
      if (vki.Voer.length > (regel-1) && vki.Levering?.length > (regel-1))
      {
        let soortvoer:string | undefined = vki.Voer[(regel-1)].SoortVoer;
        let coc:string | undefined = vki.Voer[(regel-1)].Coccidiostaticum;
        if (soortvoer != undefined && coc !== undefined && soortvoer.indexOf('nd') > -1 || (soortvoer == undefined ))
        {
          if ( vki.Voer?.length > regel)
          {
            vki.Voer[(regel)].Begindatum = undefined;
          }
        }
      }
      return vki;
    }

    // bij einddatum voer n
    static zetBegindatumvoerEindatumVoer(vki:IVKI, regel:number) :IVKI
    {
      if (vki.Voer.length > (regel-1) )
      {
        let soortvoer:string | undefined = vki.Voer[(regel-1)].SoortVoer;
        if (soortvoer != undefined && soortvoer.indexOf('nd') == -1)
        {
          if ( vki.Voer?.length > regel)
          {
            vki.Voer[(regel)].Einddatum = vki.Voer[(regel)].Begindatum;
          }
        }
      }
      return vki;
    }

    // bij einddatum voer n
    static zetEinddatumVoer(vki:IVKI, regel:number) :IVKI
    {
      if (vki.Voer?.length > (regel-1) )
      {
        let soortvoer:string | undefined = vki.Voer[(regel-1)].SoortVoer;
        if (soortvoer != undefined && soortvoer.indexOf('nd') == -1)
        {
          if ( vki.Voer.length > regel)
          {
            vki.Voer[(regel)].Einddatum = vki.Voer[(regel)].Begindatum;
          }
        }
      }
      return vki;
    }
    
    static valideerVerplichteVelden(vki:IVKI) :string
    {
      let result:string = "";
      if (vki.DatumVanInvullen === undefined || vki.DatumVanInvullen === null || isNaN(vki.DatumVanInvullen?.getTime()))
      {
        result += t('valideerDatumVanInvullen') + "<br />";
      }
      if (vki.Opzetdatum === undefined || vki.Opzetdatum === null|| isNaN(vki.DatumVanInvullen?.getTime()))
      {
        result += t('valideerOpzetDatum') + "<br />";
      }
      if (vki.Stalnummer === undefined || vki.Stalnummer === null|| vki.Stalnummer === 0)
      {
        result += t('valideerStalnummer') + "<br />";
      }
      if (vki.Bedrijfslocatie === undefined || vki.Bedrijfslocatie === null || vki.Bedrijfslocatie === "")
      {
        result += t('valideerBedrijfslocatie') + "<br />";
      }
      if (vki.Pluimveehouder.Naam === undefined || vki.Pluimveehouder.Naam === null || vki.Pluimveehouder.Naam === "")
      {
        result += t('valideerPluimveehouderNaam') + "<br />";
      }
      if (vki.Pluimveehouder.Adres === undefined || vki.Pluimveehouder.Adres === null || vki.Pluimveehouder.Adres === "")
      {
        result += t('valideerPluimveehouderAdres') + "<br />";
      }
      if (vki.Pluimveehouder.Woonplaats === undefined || vki.Pluimveehouder.Woonplaats === null || vki.Pluimveehouder.Woonplaats === "")
      {
        result += t('valideerPluimveehouderWoonplaats') + "<br />";
      }
      if (vki.Voerleverancier.Naam === undefined || vki.Voerleverancier.Naam === null || vki.Voerleverancier.Naam === "")
      {
        result += t('valideerVoerleverancierNaam') + "<br />";
      }
      if (vki.Voerleverancier.Adres === undefined || vki.Voerleverancier.Adres === null || vki.Voerleverancier.Adres === "")
      {
        result += t('valideerVoerleverancierAdres') + "<br />";
      }
      if (vki.Voerleverancier.Woonplaats === undefined || vki.Voerleverancier.Woonplaats === null || vki.Voerleverancier.Woonplaats === "")
      {
        result += t('valideerVoerleverancierWoonplaats') + "<br />";
      }
      if (vki.Dierenarts.Praktijk === undefined || vki.Dierenarts.Praktijk === null || vki.Dierenarts.Praktijk === "")
      {
        result += t('valideerDierenartsPraktijk') + "<br />";
      }
      if (vki.Slachthuis.Naam === undefined || vki.Slachthuis.Naam === null || vki.Slachthuis.Naam === "")
      {
        result += t('valideerSlachthuisNaam') + "<br />";
      }
      if (vki.Slachthuis.Email === undefined || vki.Slachthuis.Email === null || vki.Slachthuis.Email === "")
      {
        result += t('valideerSlachthuisEmail') + "<br />";
      }

      return result;
    }
    // // bij Ziektebeeld n
    // static zetGeneesmiddel(vki:IVKI, regel:number) :IVKI
    // {
    //   if (vki.Gezondheid?.length > (regel-1) )
    //   {
    //     let ziektebeeld:string | undefined = vki.Gezondheid[(regel-1)].Ziektebeeld;
    //     let geneesmiddel:string | undefined = vki.Gezondheid[(regel-1)].RegnrGebruikteGeneesmiddel;
    //     if (ziektebeeld != undefined &&  (ziektebeeld.toLowerCase() === "geen" || ziektebeeld.toLowerCase() === "keine") && (geneesmiddel === undefined || geneesmiddel === null))
    //     {
    //       if (vki.Taal === "NL")
    //       {
    //         geneesmiddel = "geen geneesmiddelen gebruikt";
    //       }
    //       else
    //       {
    //         geneesmiddel = "keine Medikamente";
    //       }
    //     }
    //   }
    //   return vki;
    // }

    // bij het laden van het formulier @@@@@@@ Als er een readonly modus is hoeft het niet.
    static zetBeginDatumVoer1(vki:IVKI, regel:number) :IVKI
    {
      // check if begindatum al gezet is of niet? @ na vragen of dit op deze manier kan....
      if (vki.Voer?.length > (regel-1) && vki.Voer[(regel-1)]?.Begindatum === undefined)
      {
        vki.Voer[(regel-1)].Begindatum = vki.Opzetdatum
      }
      return vki;
    }
}