import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Checkbox, FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { getInitialProps, useTranslation } from 'react-i18next';
import { IFormValidationObject, IKoppelgegevensDataSectionProps } from '../../VKI/Interfaces/IForm';
import { IRas, IVKI } from '../../VKI/Interfaces/IVKI';
import { IVKIFormItem } from '../../VKI/Interfaces/VKIForm';

const KoppelgegevensDataSection: React.FC<IKoppelgegevensDataSectionProps> = ({ vkiData, accountInfo, onChange, isReadonly, groupClaim, rasSelectBoxOptions, validateObject, setValidationObject }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const intialProps = getInitialProps();
  const [isChecked, setIsChecked] = useState(vkiData && vkiData.HasCheckedVkiInfo);
  useEffect(() => {
    // Check for missing inputs in vkiData.Koppelgegevens
    const checkMissingInputs = () => {
      if (vkiData && vkiData.Koppelgegevens && initialLoad) {
        const { NaamBroederij, Soortpluimvee, Rassen, VBnummers} = vkiData.Koppelgegevens;
        let isDataMissing = false;

        if (!NaamBroederij) isDataMissing = true;
        if (!Soortpluimvee) isDataMissing = true;
        if (!Rassen || Rassen.length === 0) isDataMissing = true;        
        if (!VBnummers || VBnummers.length === 0) isDataMissing = true;        

        // set colapsing state false when there is not data missing.
        if(isDataMissing === false) {
          //setExpanded(isDataMissing);
        }
      }
    };
    
    checkMissingInputs();
    setInitialLoad(false);
    if(vkiData) isKoppelgegevensValid(vkiData);
  }, [vkiData]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, } = event.target;
    onChange((prevVkiData: IVKI) => ({
      ...prevVkiData,
      Koppelgegevens: {
        ...prevVkiData.Koppelgegevens,
        [name]: value 
      },
    }));
  };

  const handleInputChangeVkiInfo = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    onChange((prevVkiData:IVKI) => ({
      ...prevVkiData,
      HasCheckedVkiInfo: newValue as boolean,
    }));
    setIsChecked(newValue as boolean);
    console.log(vkiData);

  };

  const handleRasChange = (event: SelectChangeEvent<string>, index: number) => {
    // this is the externeId
    const { value } = event.target;

    onChange((prevVkiData: IVKI) => {
      const updatedVkiData: IVKI = prevVkiData;
      const rassen = updatedVkiData.Koppelgegevens.Rassen;

      if(rasSelectBoxOptions === undefined) return;
      const selectedRas = rasSelectBoxOptions.find((ras => ras.ExterneId === value));
      //return when not found. because value cant be set....

      if(rassen && rassen.length === 0 && value !=='empty') { 
        // if rassen is empty make a object..
        if(selectedRas === undefined) return;
        rassen[0] = {} as IRas;
        rassen[0].Naam = selectedRas.Titel;
        rassen[0].ExterneId = selectedRas.ExterneId;
      } else if (value === 'empty') {
        if(rassen[index] === undefined) return;
        if(rassen.length === 1) {
          updatedVkiData.Koppelgegevens.Rassen = []; 
        }
        rassen.splice(index, 1);
      } 
      else {
        // if rassen is not set yet make a new object.... for index 1 or 2
        if(rassen[index] === undefined){
          rassen[index] = {} as IRas;
        }
        if(selectedRas === undefined) return;


        let ras: IRas = rassen[index];
        if (ras === null || ras === undefined)
        {
          ras = {} as IRas;
        }
        ras.Naam = selectedRas.Titel;
        ras.ExterneId = selectedRas.ExterneId;
      }
      
      isKoppelgegevensValid(updatedVkiData);

      return updatedVkiData;
    });
  };

  const handleVBnumberChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: any) => {
    const { value } = event.target;

    onChange((prevVkiData: IVKI) => {
      const updatedVkiData: IVKI = prevVkiData; // Make a copy of the previous data
  
      if (!updatedVkiData.Koppelgegevens.VBnummers) updatedVkiData.Koppelgegevens.VBnummers = [];
     
      // update array...
      updatedVkiData.Koppelgegevens.VBnummers[index] = {Naam: value, ExterneId: ''};
    
      // remove all empty, null or undefined values from array.
      
      // bij het opslaan van het formulier moet dat gedaan worden..... anders.niet.

  
      isKoppelgegevensValid(updatedVkiData);
  
      return updatedVkiData;
    });
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const isKoppelgegevensValid = (data: IVKI): void => {
    let isValid:boolean = true;
    if(data)  {
      if(data.Koppelgegevens) {
        const { Rassen, VBnummers} = data.Koppelgegevens;
        if(!Rassen || Rassen.length === 0) isValid = false;
        if(!VBnummers || VBnummers.length === 0) isValid = false;
      }
    }
    // update the validation object..
    setValidationObject((prevValidationObject: IFormValidationObject) => ({
      ...prevValidationObject,
      KoppelgegevensDataSection: isValid,
    }));
  };

  const handleKeyPress = (event:any) => {
    if (event.key === 'Enter') {
      try
      {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        // You can add any custom logic here if needed
        const form = event.target.form;
        const index = Array.from(form.elements).indexOf(event.target);
        form.elements[index + 1].focus();
      }
      catch(ex){}
    }
  };

  // const showVbNummerValue = (koppelGegevens: IKoppelgegevens | undefined) => {
  //   if (!koppelGegevens || !koppelGegevens.VBnummers) {
  //       return '';
  //   }

  //   const vbNummers = koppelGegevens.VBnummers
  //       .slice(0, 10)
  //       .map(vbNummer => vbNummer.Naam)
  //       .join(',');

  //   return vbNummers;
  // }
  
  return (
    <>
    <div
      className={`default-section-container ${expanded ? 'expanded' : 'collapsed'}`}
    >
      <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 style={{ marginRight: 'auto' }}>{t('koppelGegevensCaps')}</h4>
          <IconButton
            onClick={toggleExpand}
            className={`expand-button ${expanded ? 'expanded' : ''}`}
            aria-label={expanded ? t('collapse') as string | undefined: t('expand')  as string | undefined}
            style={{ marginLeft: 'auto' }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        {expanded && (
        <div className="content">
          <div className="default-row">
            <TextField onKeyPress={handleKeyPress} 
              name="NaamBroederij"
              label={t('naamBroederij')}
              InputProps={{
                readOnly: isReadonly,
              }}
              value={vkiData && vkiData.Koppelgegevens && vkiData.Koppelgegevens.NaamBroederij ? vkiData.Koppelgegevens.NaamBroederij : ''}
              error={!vkiData?.Koppelgegevens || !vkiData?.Koppelgegevens.NaamBroederij}
              helperText={!vkiData?.Koppelgegevens || !vkiData?.Koppelgegevens.NaamBroederij || !('NaamBroederij' in vkiData.Koppelgegevens) ? t('verplichtVeld') : ''}
              onChange={handleInputChange}
              className='default-field-NaamBroederij'
              InputLabelProps={{
                className: 'input-label', 
                classes: {
                  shrink: 'input-label-shrink', 
                },
              }}
            />
          </div>
          <div className="default-row">
            <TextField onKeyPress={handleKeyPress} 
              name="Soortpluimvee"
              label={t('soortpluimvee')}
              InputProps={{
                readOnly: true,
              }}
              value={vkiData && vkiData.Koppelgegevens && vkiData.Koppelgegevens.Soortpluimvee ? vkiData.Koppelgegevens.Soortpluimvee : ''}
              onChange={handleInputChange}
              className='default-field-Soortpluimvee'
              InputLabelProps={{
                className: 'input-label', 
                classes: {
                  shrink: 'input-label-shrink', 
                },
              }}
            />
          </div>
          <div className="default-row">
            <div className="default-label-rassen">{t('ras')}
              <span className="default-label-splitter"> : </span>
            </div>
              <div className="default-column-rassen">
                <div className='default-field-Ras'>
                <Select onKeyPress={handleKeyPress} 
                  key={0}
                  name={`Ras`}
                  disabled={isReadonly}
                  label={t('ras')}
                  value={
                    vkiData &&
                    vkiData.Koppelgegevens &&
                    vkiData.Koppelgegevens.Rassen &&
                    vkiData.Koppelgegevens.Rassen[0] &&
                    vkiData.Koppelgegevens.Rassen[0].ExterneId
                      ? vkiData.Koppelgegevens.Rassen[0].ExterneId
                      : ''
                  }
                  onChange={(event) => handleRasChange(event, 0)}
                  className='default-field-Ras-input'
                  size="small"
                  style={{border: !vkiData?.Koppelgegevens || !vkiData?.Koppelgegevens.Rassen || !('Rassen' in vkiData.Koppelgegevens) || !vkiData?.Koppelgegevens.Rassen[0] || vkiData?.Koppelgegevens.Rassen[0]?.Naam === '' ? '1px solid red' : ''}}
                >
                   <MenuItem value={"empty"} style={{ color: 'transparent', width: '80%' }}>{"Empty Selection"}</MenuItem>
                   {rasSelectBoxOptions
                      ?.filter((ras: IVKIFormItem) => ras.Taal.toLowerCase() === intialProps.initialLanguage.toLowerCase())
                      .map((ras: IVKIFormItem, index: number) => (
                        <MenuItem key={index} value={ras.ExterneId}>
                          {ras.Titel}
                        </MenuItem>
                      ))}
                </Select>
                  {!vkiData?.Koppelgegevens || !vkiData?.Koppelgegevens.Rassen ||  !('Rassen' in vkiData.Koppelgegevens) || !vkiData?.Koppelgegevens.Rassen[0] || vkiData?.Koppelgegevens.Rassen[0].Naam === '' ? <FormHelperText error>{t('verplichtVeld')}</FormHelperText> : null}
                </div>
            </div>
          </div>
          <div className="default-row">
            <div className="default-label-rassen">{t('vbnummers') }
              <span className="default-label-splitter"> : </span>
            </div>
            <div className="default-column-vbnummers">
              <div className='default-field-vbnummers'>
                <TextField onKeyPress={handleKeyPress} 
                  name="vbNummers"
                  key={0}
                  InputProps={{
                    readOnly: isReadonly,
                    inputProps: { maxLength: 15 },
                    classes: {
                      root: 'default-field-vbnummers-input', 
                    },
                  }}
                  InputLabelProps={{
                    className: 'input-label', 
                    classes: {
                      shrink: 'input-label-shrink', 
                    },
                  }}
                  placeholder={t('vbnummeruitlegplaceholder') as string}
                  value={vkiData?.Koppelgegevens.VBnummers[0]? vkiData?.Koppelgegevens.VBnummers[0]?.Naam : ''}
                  onChange={(event) => handleVBnumberChange(event, 0)}
                  className='default-field-vbnummers-input'
                  error={!vkiData?.Koppelgegevens?.VBnummers || 
                    !Array.isArray(vkiData.Koppelgegevens.VBnummers) || 
                    vkiData.Koppelgegevens.VBnummers.length === 0 || 
                    vkiData.Koppelgegevens.VBnummers.every(vb => !vb || vb.Naam === '')}
                  helperText={!vkiData?.Koppelgegevens?.VBnummers || 
                    !Array.isArray(vkiData.Koppelgegevens.VBnummers) || 
                    vkiData.Koppelgegevens.VBnummers.length === 0 || 
                    vkiData.Koppelgegevens.VBnummers.every(vb => !vb || vb.Naam === '') ? t('verplichtVeld') : ''}
                />
              </div>
              <div className='default-field-vbnummers'>
                <TextField onKeyPress={handleKeyPress} 
                    name="vbNummers"
                    key={1}
                    InputProps={{
                      readOnly: isReadonly,
                      inputProps: { maxLength: 15 },
                      classes: {
                        root: 'default-field-vbnummers-input', 
                      },
                    }}
                    InputLabelProps={{
                      className: 'input-label', 
                      classes: {
                        shrink: 'input-label-shrink', 
                      },
                    }}
                    placeholder={t('vbnummeruitlegplaceholder') as string}
                    value={vkiData?.Koppelgegevens.VBnummers[1]? vkiData?.Koppelgegevens.VBnummers[1]?.Naam : ''}
                    onChange={(event) => handleVBnumberChange(event, 1)}
                    className='default-field-vbnummers-input'
                  />
              </div>
              <div className='default-field-vbnummers'>
                <TextField onKeyPress={handleKeyPress} 
                    name="vbNummers"
                    key={2}
                    InputProps={{
                      readOnly: isReadonly,
                      inputProps: { maxLength: 15 },
                      classes: {
                        root: 'default-field-vbnummers-input', 
                      },
                    }}
                    InputLabelProps={{
                      className: 'input-label', 
                      classes: {
                        shrink: 'input-label-shrink', 
                      },
                    }}
                    placeholder={t('vbnummeruitlegplaceholder') as string}
                    value={vkiData?.Koppelgegevens.VBnummers[2]? vkiData?.Koppelgegevens.VBnummers[2]?.Naam : ''}
                    onChange={(event) => handleVBnumberChange(event, 2)}
                    className='default-field-vbnummers-input'
                  />
              </div>
              <div className='default-field-vbnummers'>
                <TextField onKeyPress={handleKeyPress} 
                  name="vbNummers"
                  key={3}
                  InputProps={{
                    readOnly: isReadonly,
                    inputProps: { maxLength: 15 },
                    classes: {
                      root: 'default-field-vbnummers-input', 
                    },
                  }}
                  InputLabelProps={{
                    className: 'input-label', 
                    classes: {
                      shrink: 'input-label-shrink', 
                    },
                  }}
                  placeholder={t('vbnummeruitlegplaceholder') as string}
                  value={vkiData?.Koppelgegevens.VBnummers[3]? vkiData?.Koppelgegevens.VBnummers[3]?.Naam : ''}
                  onChange={(event) => handleVBnumberChange(event, 3)}
                  className='default-field-vbnummers-input'
                />
              </div>
              <div className='default-field-vbnummers'>
              <TextField onKeyPress={handleKeyPress} 
                  name="vbNummers"
                  key={4}
                  InputProps={{
                    readOnly: isReadonly,
                    inputProps: { maxLength: 15 },
                    classes: {
                      root: 'default-field-vbnummers-input', 
                    },
                  }}
                  InputLabelProps={{
                    className: 'input-label', 
                    classes: {
                      shrink: 'input-label-shrink', 
                    },
                  }}
                  placeholder={t('vbnummeruitlegplaceholder') as string}
                  value={vkiData?.Koppelgegevens.VBnummers[4]? vkiData?.Koppelgegevens.VBnummers[4]?.Naam : ''}
                  onChange={(event) => handleVBnumberChange(event, 4)}
                  className='default-field-vbnummers-input'
                />
              </div>
              
            </div>
          </div>
        {/* Als er 5 vbnummers ingevuld zijn een nieuwe regel laten zien  */}
        {vkiData?.Koppelgegevens.VBnummers && vkiData?.Koppelgegevens.VBnummers.length >= 5 && (
          <div className="default-row">
            <div className="default-label-rassen">
              <span className="default-label-splitter"></span>
            </div>
            <div className="default-column-vbnummers">
              <div className='default-field-vbnummers'>
              <TextField onKeyPress={handleKeyPress} 
                  name="vbNummers"
                  key={5}
                  InputProps={{
                    readOnly: isReadonly,
                    inputProps: { maxLength: 15 },
                    classes: {
                      root: 'default-field-vbnummers-input', 
                    },
                  }}
                  InputLabelProps={{
                    className: 'input-label', 
                    classes: {
                      shrink: 'input-label-shrink', 
                    },
                  }}
                  placeholder={t('vbnummeruitlegplaceholder') as string}
                  value={vkiData?.Koppelgegevens.VBnummers[5]? vkiData?.Koppelgegevens.VBnummers[5]?.Naam : ''}
                  onChange={(event) => handleVBnumberChange(event, 5)}
                  className='default-field-vbnummers-input'
                />
              </div>
              <div className='default-field-vbnummers'>
              <TextField onKeyPress={handleKeyPress} 
                  name="vbNummers"
                  key={6}
                  InputProps={{
                    readOnly: isReadonly,
                    inputProps: { maxLength: 15 },
                    classes: {
                      root: 'default-field-vbnummers-input', 
                    },
                  }}
                  InputLabelProps={{
                    className: 'input-label', 
                    classes: {
                      shrink: 'input-label-shrink', 
                    },
                  }}
                  placeholder={t('vbnummeruitlegplaceholder') as string}
                  value={vkiData?.Koppelgegevens.VBnummers[6]? vkiData?.Koppelgegevens.VBnummers[6]?.Naam : ''}
                  onChange={(event) => handleVBnumberChange(event, 6)}
                  className='default-field-vbnummers-input'
                />
              </div>
              <div className='default-field-vbnummers'>
              <TextField onKeyPress={handleKeyPress} 
                  name="vbNummers"
                  key={7}
                  InputProps={{
                    readOnly: isReadonly,
                    inputProps: { maxLength: 15 },
                    classes: {
                      root: 'default-field-vbnummers-input', 
                    },
                  }}
                  InputLabelProps={{
                    className: 'input-label', 
                    classes: {
                      shrink: 'input-label-shrink', 
                    },
                  }}
                  placeholder={t('vbnummeruitlegplaceholder') as string}
                  value={vkiData?.Koppelgegevens.VBnummers[7]? vkiData?.Koppelgegevens.VBnummers[7]?.Naam : ''}
                  onChange={(event) => handleVBnumberChange(event, 7)}
                  className='default-field-vbnummers-input'
                />
              </div>
              <div className='default-field-vbnummers'>
              <TextField onKeyPress={handleKeyPress} 
                  name="vbNummers"
                  key={8}
                  InputProps={{
                    readOnly: isReadonly,
                    inputProps: { maxLength: 15 },
                    classes: {
                      root: 'default-field-vbnummers-input', 
                    },
                  }}
                  InputLabelProps={{
                    className: 'input-label', 
                    classes: {
                      shrink: 'input-label-shrink', 
                    },
                  }}
                  placeholder={t('vbnummeruitlegplaceholder') as string}
                  value={vkiData?.Koppelgegevens.VBnummers[8]? vkiData?.Koppelgegevens.VBnummers[8]?.Naam : ''}
                  onChange={(event) => handleVBnumberChange(event, 8)}
                  className='default-field-vbnummers-input'
                />
              </div>
              <div className='default-field-vbnummers'>
              <TextField onKeyPress={handleKeyPress} 
                  name="vbNummers"
                  key={9}
                  InputProps={{
                    readOnly: isReadonly,
                    inputProps: { maxLength: 15 },
                    classes: {
                      root: 'default-field-vbnummers-input', 
                    },
                  }}
                  InputLabelProps={{
                    className: 'input-label', 
                    classes: {
                      shrink: 'input-label-shrink', 
                    },
                  }}
                  placeholder={t('vbnummeruitlegplaceholder') as string}
                  value={vkiData?.Koppelgegevens.VBnummers[9]? vkiData?.Koppelgegevens.VBnummers[9]?.Naam : ''}
                  onChange={(event) => handleVBnumberChange(event, 9)}
                  className='default-field-vbnummers-input'
                />
              </div>
            </div>
          </div>
          )}
        </div>  
        )}
    </div>
    <div>
      <div className="checkVKIInfo">
        {t('vkiInfoChecked')}
        <Checkbox
                  name="checkVKIInfo"
                  checked={isChecked}
                  disabled={isReadonly}
                  onChange={handleInputChangeVkiInfo}
                  className="default-field-checkVKIinfo-checkbox"
                  size="small"
                  style={{border: (!isChecked && !isReadonly) ? '1px solid red' : ''}}
                />
                
      </div>
    </div>
    </>
  );
};

export default KoppelgegevensDataSection;
